import { globalActions } from 'primary-components';

import FORMULAS from 'resources/constants/Formulas.js';
import properties from 'resources/constants/properties.json';

import DataprepUtils from './DataprepUtils';
import FileUtils from './FileUtils';
import TimeSeriesUtils from './TimeSeriesUtils';

const FUNCTIONS_NAMESPACE = properties.functionsNamespace
export default(() => {

  const insertTable = async ({ values, address, tableName }) =>
    Excel.run(context => {
      const worksheet = context.workbook.worksheets.getItem(address.split("!")[0]),
            range = worksheet.getRange(address.split("!")[1]),
            rangeBelow = range.getRowsBelow(1),
            resizeRange = rangeBelow.getAbsoluteResizedRange(values.length, values?.[0]?.length || 0),
            tables = resizeRange.getTables(false)
      resizeRange.numberFormat = "@"
      tables.load("items")
      worksheet.comments.load("items")
      rangeBelow.load("address")

      return context.sync().then(() => {
        const comment = worksheet.comments.getItemByCell(address)
        comment?.delete()
        let table = tables.items?.[0] 
        return context
          .sync()
          .catch(err => {
            console.info("Comment does not exist")
            console.info(String(err))
          })
          .finally(() => {
            let oldRange
            if(table) {
              oldRange = table?.getRange()
              oldRange.load("values")
              oldRange.load("address")
            }
            return context
            .sync()
            .catch(err => {
              console.info("Table does not exist")
              console.info(String(err))
            })
            .finally(() => {
                if(oldRange) {
                  const newValues = oldRange.values.map(val => val.map(() => ""))
                  oldRange.values = newValues
                }
                resizeRange.values = values
                if(!table) {
                  table = worksheet.tables.add(resizeRange, true)
                  table.style = null;
                  table.showFilterButton = false;
                } else {
                  table.resize(resizeRange)
                }
                return context.sync().then(() => {
                  worksheet.comments.add(address, tableName)
                  if (Office.context.requirements.isSetSupported("ExcelApi", "1.2")) {
                    resizeRange.format.autofitColumns();
                    resizeRange.format.autofitRows();
                  }
                  return context.sync()
                })
              })
          })
      })
      .catch(err => {
        console.error(err)
        console.error(String(err))
        throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, err)
      })
    }).catch(err => {
      console.error(err)
      console.error(String(err))
      throw new CustomFunctions.Error(CustomFunctions.ErrorCode.notAvailable, err)
    })


  const addFormulaToCell = ({ formula, address }) =>
    Excel.run(context => {
      let range;
      if(address) {
        const worksheet = context.workbook.worksheets.getItem(address.split("!")[0])
        
        range = worksheet.getRange(address.split("!")[1])
      } else {
        range = context.workbook.getSelectedRange()
      }
      range.load("values")

      return context.sync().then(() => {
        if(range.values[0][0] && !address) {
          window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(globalActions.getMessageStore()).error("Value exists in a cell")
        } else {
          range.formulas = [[formula]]
        }
      })

    }).catch(err => {
      console.error(err)
      console.error(String(err))
      return Promise.resolve()
    })

  const refreshFormulaWithAdress = ({ address, formula, context, worksheet }) => {
    const worksheetLocal = worksheet ? worksheet : context.workbook.worksheets.getItem(address.split("!")[0]),
          range = worksheetLocal.getRange(address.split("!")[1])
    range.load("formulas")
    return context.sync().then(() => {
      if(range.formulas[0][0]) {
        range.calculate()
      } else {
        range.formulas = [[formula]]
      }
    }).catch(e => console.error(e))
  }

  const getTable = ({ table, context, item, comments, validateTable }) => {
    const range = table.getRange();
    const firstCell = range.getCell(0, 0)
    const cell = firstCell.getRowsAbove(1)
    const cellComment = comments.getItemByCell(cell)
    cellComment.load("content")
    cell.load("address")
    cell.load("formulas")
    return context.sync()
      .then(() => {
        const cellValue = cellComment.content
        return validateTable({ item, cellValue, cell, formula: cell.formulas?.[0]?.[0] })
      })
      .catch(err => {
        console.error("Comment does not exist", err)
        return undefined
      })
  }
  const getTables = ({ worksheet, context, prevTables, ...props }) => {
    worksheet.tables.load("items")
    worksheet.comments.load("items")
    return context.sync()
      .then(() => 
        prevTables.concat(
          worksheet.tables.items.reduce((prev, table) => 
            prev.then((item = []) => getTable({ table, context, item, comments: worksheet.comments, ...props }))
          , Promise.resolve())
        )
      )
      .catch(err => {
        console.error(err)
      })
  }

  const getTablesInSpreadSheets = ({ ...props }) => 
  Excel.run(context => {
    context.workbook.worksheets.load("items")
    return context.sync()
      .then(() => {
        const worksheetsPromise = context.workbook.worksheets.items
          .reduce((prevPromise, worksheet) => 
              prevPromise
                .then((prevTables = []) => getTables({ worksheet, context, prevTables, ...props }).then(items => items.filter(i => i !== undefined)))
                .catch(err => {
                  console.error(err)
                })
          , Promise.resolve())
        return worksheetsPromise
      })
      .then(dps => {
        const ps = Promise.all(dps).then(dps => {
          const flatDps = dps.flatMap(d => d)
          return flatDps;
        })
        return ps;
      })
      .catch(err => {
        console.error(err)
      })
  })

  const getSelectedRange = () => 
    Excel.run(context => {
      const range = context.workbook.getSelectedRange()
      range.load("address")

      return context.sync().then(() => {
        const addressSplit = range.address.split("!"),
              sheetName = addressSplit[0],
              cells = addressSplit[1].split(":"),
              firstCell = cells[0],
              lastCell = cells[1]

        return { sheetName, firstCell, lastCell }
      })
    })

  const refreshFormulasForEntity = async ({ worksheet, context, validateTable, getFormula }) => {
    await worksheet.tables.items.reduce((prev, tableItem) => 
      prev.then(() => 
        getTable({ table: tableItem, context, comments: worksheet.comments, validateTable, item: [] })
          .then(table => {
            if(table?.[0]) {
              return refreshFormulaWithAdress({ address: table[0].address, formula: getFormula(table[0]), context })
            }
            return Promise.resolve()
          })
    ), Promise.resolve())
  }

  const refreshAllFormulasInSheet = async ({ worksheet, context }) => {
    worksheet.tables.load("items")
    worksheet.comments.load("items")
    await context.sync().catch(e => console.error(e))
    // we need to handle here all formulas
    await refreshFormulasForEntity({ getFormula: item => `=${FORMULAS.LOAD_DATAPREP}("${item.name}")`, validateTable: DataprepUtils.validateTable, worksheet, context })
    await refreshFormulasForEntity({ getFormula: item => `=${FORMULAS.LOAD_FILE}("${item.fileName}","${item.groupName}")`, validateTable: FileUtils.validateTable, worksheet, context })
    await refreshFormulasForEntity({ getFormula: item => `=${FORMULAS.LOAD_TS}("${item.groupName}","${item.values?.join(",")}",${item.symbols})`, validateTable: TimeSeriesUtils.validateTable, worksheet, context })
  }

  const refreshAllFormulas = async ({ context }) => {
    const worksheets = context.workbook.worksheets
    worksheets.load("items")
    await context.sync().catch(e => console.error(e))
    await worksheets.items.reduce((prev, worksheet) => 
      prev.then(() => refreshAllFormulasInSheet({ worksheet, context }))
    , Promise.resolve())
  }

  const goToAddress = async address => 
    await Excel.run(context => {
      const worksheet = context.workbook.worksheets.getItem(address.split("!")[0]),
            range = worksheet.getRange(address.split("!")[1])
      range.select()
      return context.sync()
    })

  const openTaskPaneComponent = componentName => {
    document.body.id = componentName
    Office.addin.showAsTaskpane()
  }

  const switchButton = async ({ tabId, groupId, buttonId, enabled = false }) => {
    if(Office.context.requirements.isSetSupported('RibbonApi', '1.1')) {
      await Office.ribbon
        .requestUpdate({
          tabs: [
            {
              id: tabId,
              groups: [
                {
                  id: groupId,
                  controls: [{ id: buttonId, enabled }],
                },
              ],
            },
          ],
        })
        .catch(err => console.error(err));
    }
  }

  return {
    insertTable,
    addFormulaToCell,
    refreshFormulaWithAdress,
    getTablesInSpreadSheets,
    refreshAllFormulasInSheet,
    refreshAllFormulas,
    goToAddress,
    openTaskPaneComponent,
    switchButton,
    getSelectedRange
  }

})()