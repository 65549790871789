import { DataStorageApi, formatTypes, GetDataBody, Key, tsDataRangesObject } from 'dashboard-services';

import getApiConfig from 'api/ApiConfig';
import Formulas from 'resources/constants/Formulas.js';
import { Parameters } from 'utils';

import Papa from 'papaparse';

export default(() => {
  const TS_GROUP_PREFIX = "Symbol Group: "
  const TS_COLUMNS_PREFIX = "Values: "
  const TS_SYMBOLS_PREFIX = "Symbols: "

  const SUPPORTED_TS_PARAMS = ["timeZone", "range", "startDate", "endDate", "lastType", "lastTypeAmount"]

  const getBody = ({ symbol, groupName, values, timeZone, range, startDate, endDate, lastType, lastTypeAmount } = {}) => {
    let builder = new GetDataBody.Builder()
    const key = new Key.Builder()
      .withColumns(String(values) === "*" ? undefined : values)
      .withSymbols(symbol)
      .withGroupName(groupName)
      .withPattern(true)
      .withPatternExactMatch(false)
      .withSymbolValuesExactMatch(true)
    builder = builder.withKey(key.build().serialize())
    if(range === tsDataRangesObject.between) {
      builder = builder
              .withStartDate(startDate)
              .withEndDate(endDate)
    }
    if(range === tsDataRangesObject.last || range === tsDataRangesObject.next) {
      builder = builder
              .withLastType(lastType)
              .withLastTypeAmount(lastTypeAmount)
    }
    const body = builder
      .withRange(range)
      .withTimeZone(timeZone)
      .withFormatType(formatTypes.NCSV)
      .build()
      .serialize()
    return body
  }

  const getData = async ({ symbol, groupName, values, timeZone, range, startDate, endDate, lastType, lastTypeAmount } = {}) => async dispatch => {
    const body = getBody({ symbol, groupName, values, timeZone, range: range ? String(range).toUpperCase() : tsDataRangesObject.all, startDate, endDate, lastType, lastTypeAmount })
    const response = await new DataStorageApi(dispatch(getApiConfig()))
      .getDataStream(body, { size: -1 })
      .withHeader('Content-Type', 'application/json')
      .withHeader('Accept', '*/*')
      .withHeader('Expect', 'text/csv')
      .noParsing()
      .build()
      .call()
    const textResponse = await response.text()
    if(!textResponse) {
      return textResponse;
    }
    const parsedResponse = 
        await new Promise((resolve, reject) =>
          Papa.parse(textResponse, {
            complete: result => {
              const newResult = result.data
              if(newResult[newResult.length - 1]?.length !== newResult[0]?.length) {
                newResult.pop()
              }
              return resolve(newResult)
            },
            error: err => {
              reject(err)
              console.error(String(err))
            }
          })
        )
    return parsedResponse;
  }


  const validateTable = ({ item, cellValue, cell, formula, returnItem = false }) => {
    if((!cellValue || String(cellValue).startsWith(TS_GROUP_PREFIX)) && (formula?.includes(Formulas.LOAD_TS) || formula?.includes(Formulas.LOAD_TS.replaceAll(".", "_")))) {
      const parsedFormula = formula.slice((`=${Formulas.LOAD_TS}`).length).replaceAll(", ", ",").slice(1, -1).split("\",").map(s => s.replaceAll("\"", ""))
      const parsedParameters = new Parameters.Builder()
        .withRequiredParameter("groupName", parsedFormula[0])
        .withRequiredArrayParameter("values", parsedFormula[1])
        .withUserParameters(parsedFormula.slice(2))
        .withSplitUserParametersFunction(({ key }) => !SUPPORTED_TS_PARAMS.includes(key))
        .build();
      const toReturn = {
        ...parsedParameters,
        groupName: parsedParameters.groupName,
        values: parsedParameters.values,
        symbols: parsedParameters.getSplittedParameters(),
        address: cell.address
      }
      if(returnItem) {
        return toReturn;
      }
      return item.concat(toReturn)
    }
    return item
  }

  return {
    getData,
    TS_COLUMNS_PREFIX,
    TS_GROUP_PREFIX,
    TS_SYMBOLS_PREFIX,
    validateTable,
    SUPPORTED_TS_PARAMS,
  }
})()