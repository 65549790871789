import { DataPrepApi } from 'dashboard-services';

import getApiConfig from 'api/ApiConfig';
import Formulas from 'resources/constants/Formulas.js';

export default(() => {
  const DP_PREFIX = "Data prep: "

  const searchDataPrepsCore = ({ input, size, noFetching = true, requestIdPrefix } = {}) => dispatch =>
    new DataPrepApi(dispatch(getApiConfig()))
      .search(input || "*", 0, size)
      .noFetching(noFetching)
      .noErrorMessage(noFetching)
      .withRequestIdPrefix(requestIdPrefix)
      .build()
      .call()


  const validateTable = ({ item, cellValue, cell, formula }) => {
    if(String(cellValue).startsWith(DP_PREFIX) && (formula?.includes(Formulas.LOAD_DATAPREP) || formula?.includes(Formulas.LOAD_DATAPREP.replaceAll(".", "_")))) {
      return item.concat({
        name: String(cellValue).replace(DP_PREFIX, ""),
        address: cell.address
      })
    }
    return item
  }

  return {
    DP_PREFIX,
    searchDataPrepsCore,
    validateTable
  }
})()