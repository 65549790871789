export class Parameters {
  _parameters = {}
  _splittedUserParameters = {}

  constructor(builder) {
    this._parameters = builder._parameters
    // set up user parameters
    builder._userParameters.forEach(userParameter => {
      const splittedRawParam = String(userParameter).split("="),
            key = splittedRawParam[0],
            value = splittedRawParam[1]
      if(!key) {
        throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, `Invalid syntax of optional parameter. Syntax: paramName=paramValue`)
      }
      let resolvedValue = this._parameters[key]?.defaultValue
      if(value !== null && String(value).trim() !== "") {
        resolvedValue = value
      }
      if(builder._splitUserParametersFunction && builder._splitUserParametersFunction({ key, value })) {
        this._splittedUserParameters[key] = resolvedValue
      } else {
        this._parameters[key] = {
          value: resolvedValue,
          optional: true
        }
      }
    })

    // set up getters to all parameters - seperate loop as we will do here more stuff
    Object.entries(builder._parameters).forEach(([name, obj]) => {
      this[name] = obj.value;
    })
  }

  getSplittedParameters() {
    return this._splittedUserParameters;
  }

  static get Builder() {
    class Builder {
      _parameters = {}
      _userParameters = []

      withRequiredParameter = (parameterName, parameterValue) => {
        if(!parameterValue || String(parameterValue).trim() === "") {
          throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, `Parameter ${parameterName} can not be blank!`)
        }
        this._parameters[parameterName] = {
          value: parameterValue,
          optional: false
        };
        return this;
      }

      withOptionalParameter = (parameterName, defaultValue) => {
        this._parameters[parameterName] = {
          defaultValue,
          optional: true
        }
        return this;
      }

      withUserParameters = (userParameters = []) => {
        this._userParameters = userParameters;
        return this;
      }

      withSplitUserParametersFunction = splitFunction => {
        this._splitUserParametersFunction = splitFunction;
        return this;
      }

      withRequiredArrayParameter = (parameterName, parameterValue) => {
        if(!parameterValue || String(parameterValue).trim() === "") {
          throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, `Parameter ${parameterName} can not be blank!`)
        }
        this._parameters[parameterName] = {
          value: String(parameterValue).split(","),
          optional: false
        }
        return this;
      }

      build = () => new Parameters(this)
    }
    return Builder;
  }
}