import { globalActions, loginActions } from 'primary-components';

import properties from 'resources/constants/properties.json';
import { AuthSingleton, ExcelUtils, LocalStorageUtils } from 'utils';
import { throttledFetch } from 'utils/ThrottleUtils';

const enableLogInButton = async () => {
  await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group3", buttonId: "NG.LogIn", enabled: true })
  await ExcelUtils.switchButton({ tabId: "NG.Tab1", groupId: "NG.Tab1.Group3", buttonId: "NG.LogOut", enabled: false })
}

const FUNCTIONS_NAMESPACE = properties.functionsNamespace
const getAuthToken = () => (dispatch, getState) => getState().primaryComponentsState.loginState.token 

const getApiConfig = () => dispatch => ({                  
  getAuthToken: () => dispatch(getAuthToken()),
  handleRequest: (...params) => dispatch(globalActions.handleRequest(...params)),
  onLogOut: () => {
    const apiUrl = LocalStorageUtils.getApiUrl()
    localStorage?.clear()
    LocalStorageUtils.setApiUrl(apiUrl)
    window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(loginActions.logOutResponse())
    enableLogInButton()
  },
  baseUrl: AuthSingleton.getInstance().getApiUrl(),
  clearLocalStorage: () => {
    localStorage?.clear()
  },
  noCors: true,
  fetch: throttledFetch
})

export default getApiConfig;