export const routing = {
  EXTERNAL: {
    documentation: {
      url: "KnowledgeCenter/Documentation/Discover/Excel%20Add-In%20User%20Guide"
    },
    storageCreator: {
      url: "Storage/Creator"
    },
    dataLake: {
      url: "Lake",
      routing: {
        files: {
          name: "Files",
        },
      }
    }
  },
  LAKE: {
    name: "DataLake"
  },
  LOGIN: {
    name: "Login"
  },
  SETTINGS: {
    name: "Settings"
  },
  TS: {
    name: "TimeSeries"
  },
  UPLOAD: {
    name: "Upload"
  },
  FALL_BACK_DIALOG: {
    MS: {
      name: "msfallbackdialog"
    },
    AUTH_0: {
      name: "auth0fallbackdialog"
    }
  }
}