import { ActionService } from 'dashboard-services';

export default (() => {
  
  const ON_CHANGE = 'ON_CHANGE_TIME_SERIES'
  const onChange = ActionService.makeActionCreator(ON_CHANGE, 'value', 'name')

  const CLEAR = 'CLEAR_TIME_SERIES'
  const clear = ActionService.makeActionCreator(CLEAR)

  const UPDATE_SYMBOL = 'UPDATE_SYMBOL_TIME_SERIES'
  const updateSymbol = ActionService.makeActionCreator(UPDATE_SYMBOL, "fun")

  return {
    onChange,
    ON_CHANGE,
    CLEAR,
    clear,
    updateSymbol,
    UPDATE_SYMBOL
  }
})()